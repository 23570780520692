import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	CardContent,
	CardMedia,
	Button,Link,
	Badge,
	Typography
} from '@material-ui/core';
import NoteCard from '../notes/_note_card';
import SpeedDials from './_speed_dials'
import NumericInput from 'react-numeric-input';
import pureIcons from "../shared/pure_icons";
import $ from "jquery";
import {useSnackbar} from 'notistack';

export default function MediaControlCard({product, reloadData}) {
	const {enqueueSnackbar} = useSnackbar();
	const classes = useStyles();
	const [maxSelect, setMaxSelect] = React.useState(product.qty);
	const [state, setState] = React.useState({
		qty: product.qty,
		original_qty: product.original_qty,
		remaining: product.qty,
		processed: product.processed,
		notes: product.notes,
		locked: product.locked,
		order_item_stage_id: product.order_item_stage_id,
		working: false
	});

	const handleQtyChange = (value) => {
		if (value > state.remaining || value <= 0 || value === null) return
		setState({...state, qty: value});
	};

	const handleNewNote = (new_note) => {
		var newNotes = [{user: 'You', gender: 'Male', message: new_note, date: ''}].concat(state.notes);
		setState({...state, notes: newNotes})
	}

	const handleLockStateChange = (new_state) => {
		setState({...state, locked: new_state})
	}

	const customization = product.customizations.map(c =>
	{if (c.value !== null && c.value !== '')
		return (<Typography variant='subtitle1' color='textSecondary' key={c.name}>
			{c.name}: <b>{c.value}</b>
		</Typography>)
		})

	const handleSubmit = (event) => {
		event.preventDefault();
		setState({...state, working: true});
		const data = {order_item_id: product.order_item_id, order_item_stage_id: product.order_item_stage_id, qty: state.qty}
		$.ajax({
			url: `/waybills/hub_processing`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function (response) {
				if (response.remaining_qty === 0) {
					reloadData(product.last_item ? product : null);
				} else {
					enqueueSnackbar(`successfully processed ${state.qty}`, {variant: 'success'})
					setMaxSelect(response.remaining_qty)
					setState({...state, working: false, qty: response.remaining_qty, remaining: response.remaining_qty, processed: response.processed});
				}
			},
			error: function (response) {
				setState({...state, working: false});
				enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	return (
		<div className='row'>
			<div className='col-md-12'>
				<Card className={classes.root}>
					<CardMedia className={classes.cover}>
						<Badge badgeContent='DG' invisible={!product.dg} overlap="circular" color="error">
							<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
						</Badge>
					</CardMedia>
					<div className={classes.details}>
						<CardContent className={classes.content}>
							<Typography component='h5' variant='h5'>
								<Link href={product.url} target="_blank">{product.name}</Link>
							</Typography>
							<div className='row'>
								<div className='col-md-7'>
									<div className='row'>
										<div className='col-md-5'>
											<Typography variant='subtitle1' color='textSecondary'>
												Customer Phone: <b>{product.customer_number}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												Barcode: <b>{product.redeem_code}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												SKU: <b>{product.sku}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												Order Number: <b><Badge badgeContent={product.new_customer ? 'new' : null} color='secondary'>{product.order_number}</Badge></b>
											</Typography>
											{product.old_customer_number &&
                        <Typography variant='subtitle1' color='textSecondary'>
                          Old Customer Numbers: <b>{product.old_customer_number}</b>
                        </Typography>
                      }
										</div>
										<div className='col-md-7'>
											{customization}
										</div>
									</div>
									{state.locked ?
										<pureIcons.LockIcon/>
										:
										<div className="btn-group" role="group">
											<div className='mt-3 p-2'>
												<NumericInput min={1} max={maxSelect} value={state.qty} mobile size={4} onChange={handleQtyChange} name='qty' className='align-middle'/>
											</div>
											<div className='mt-2 p-2'>
												<Button variant="contained" size="small" onClick={handleSubmit} startIcon={<pureIcons.GiBoxUnpacking/>} style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}>
													Process
												</Button>
											</div>
											<div className='mt-3 p-2'>
												<Typography variant='button' color='textPrimary'>
													Basket# <b>{product.basket_number}</b>
												</Typography>
											</div>
										</div>
									}
									<div >
										<SpeedDials
											product={product}
											state={state}
											newNote={handleNewNote}
											handleDeleteItem={reloadData}
											callBackLockState = {handleLockStateChange}
											handleCallBackStageQty = {reloadData}
										/>
									</div>
								</div>
								<div className='col-md-5'>
									<div>
										{state.notes && state.notes.length > 0 && <NoteCard notes={state.notes}/>}
									</div>
								</div>
							</div>
						</CardContent>
					</div>
				</Card>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 10,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
}));
