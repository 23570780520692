import React from 'react';
import Table from '../../shared/tables/material_table';
import { BsChatDots } from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import ShippingForm from './_shipping_form';
import { List, ListItem, ListItemText, Badge } from '@material-ui/core';
import ShowOrder from "../_show";
import $ from "jquery";
import { withSnackbar } from 'notistack';
import Moment from "moment";
import { ImInfo } from "react-icons/im";
import Backdrop from '../../shared/backdrop'
import { FiRefreshCw } from 'react-icons/fi'
import AutoControlPanel from './_auto_control_panel'
import TrafficIcon from '@material-ui/icons/Traffic';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import pureIcons from '../../shared/pure_icons'
class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			working: false,
			total: null,
			muiTableKey: 0,
			open: false,
		};
		this._tableRef = React.createRef();
		this._ShippingForm = React.createRef();
		this._ShowOrderRef = React.createRef();
		this._AutoControlPanelRef = React.createRef();
	}

	reloadData = (fullReload = false) => {
		if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 })
		this._tableRef.current.onQueryChange();
	}

	handleUpdate = (oldValue, newValue, columnDef, order_number) => {
		let self = this;
		if (oldValue === newValue || newValue === '' || newValue === null) return;
		let url, data;
		switch (columnDef.field) {
			case 'invoice':
				url = `/order_item_stages/update_stage_tracking`
				data = { tracking: oldValue, new_tracking: newValue, stage: 'purchased' }
				break;
			case 'eta':
				url = `/order_item_stages/update_stage`
				data = { tracking: oldValue, eta: newValue, stage: 'purchased' }
				break;
			case 'invoice_total':
				url = `/order_item_stages/update_invoice_total`
				data = { tracking: oldValue, total: newValue, order_number: order_number }
				break;
			default:
		}
		this.setState({ working: true });
		$.ajax({
			url: url,
			type: 'PATCH',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}


	handleReturnToPreviousStage = (rowData) => {
		let self = this;
		this.setState({ working: true });
		$.ajax({
			url: `/order_item_stages/return_to_previous_stage`,
			type: 'POST',
			data: { invoice: rowData.raw_invoice },
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
	render() {
		const columns = [
			{ title: 'Invoice', field: 'invoice', sorting: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 400 } },
			{ title: 'Order Number', field: 'order_number', sorting: false, editable: 'never', render: rowData => (<Badge badgeContent={rowData.new_customer ? 'new' : null} color='secondary'>{rowData.order_number}</Badge>) },
			{ title: 'Vendor', field: 'vendor', sorting: false, editable: 'never' },
			{ title: 'Items', field: 'products_count', sorting: false, editable: 'never', cellStyle: { width: '2%' } },
			{ title: 'invoice Total', field: 'invoice_total', sorting: false },
			{
				title: 'ETA', field: 'eta', type: "date", dateSetting: { format: 'dd/MM/yyyy' }, render: rowData => {
					return rowData.flag ? <div style={{ color: "#FF0000" }}>{Moment(rowData.eta).format('DD/MM/YYYY')}</div> : <div>{Moment(rowData.eta).format('DD/MM/YYYY')}</div>
				}
			},
			{ title: 'Time purchased', field: 'created_at', editable: 'never' },
		]

		return (
			<div className='bg-white rounded'>
				<ShowOrder ref={this._ShowOrderRef} />
				<Backdrop open={this.state.working} />
				<ShippingForm ref={this._ShippingForm} reloadData={this.reloadData} />
				<AutoControlPanel ref={this._AutoControlPanelRef} />
				<Table
					title={'Purchased Orders (Not shipped yet)'}
					key={this.state.muiTableKey}
					options={{ filtering: false, debounceInterval: 1000, actionsColumnIndex: -1, focus: true }}
					localization={{ toolbar: { searchPlaceholder: 'invoice or order number' } }}
					columns={columns}
					tableRef={this._tableRef}
					// cellEditable={{
					// 	onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
					// 		return new Promise((resolve, reject) => {
					// 			setTimeout(() => {
					// 				if (columnDef.field === 'invoice_total' && ((rowData.subtotal + (rowData.subtotal * 0.025)) < newValue || (rowData.subtotal - (rowData.subtotal * 0.1)) > newValue))
					// 					window.confirm(`Invoice is different than subtotal (${rowData.subtotal.toFixed(2)})`) && this.handleUpdate(rowData.raw_invoice, newValue, columnDef, rowData.order_number);
					// 				else
					// 					this.handleUpdate(rowData.raw_invoice, newValue, columnDef, rowData.order_number);
					// 				resolve();
					// 			}, 500)
					// 		});
					// 	}
					// }}
					actions={
						[
						// {
						// 	icon: () => <TrafficIcon />,
						// 	onClick: () => {
						// 		this._AutoControlPanelRef.current.handleOpen()
						// 	},
						// 	isFreeAction: true,
						// 	tooltip: 'Auto control'
						// },
						{
							icon: () => <FiRefreshCw />,
							onClick: () => { this.reloadData() },
							isFreeAction: true,
							tooltip: 'Refresh'
						},
						// rowData => ({
						// 	icon: () => <FaShippingFast />,
						// 	tooltip: <span>Ship {rowData.vendor}</span>,
						// 	onClick: (event, rowData) => {
						// 		this._ShippingForm.current.handleOpen(rowData.raw_invoice, rowData.order_number, rowData.vendor, rowData.id);
						// 	},
						// 	position: 'row',
						// }),
						rowData => ({
							icon: () => <BsChatDots />,
							tooltip: rowData.notes.length !== 0 && <List dense={true}>
								{rowData.notes.map(note =>
									<ListItem key={note}>
										<ListItemText primary={note} />
									</ListItem>,
								)}
							</List>,
							position: 'row',
							disabled: rowData.notes.length === 0,
						}),
						// rowData => ({
						// 	icon: () => <pureIcons.MdPreview />,
						// 	tooltip: rowData.is_reviewed === true ? <span>Reviewed By: {rowData.reviewed_by}</span> : <span>Mark as Reviewed</span>,
						// 	onClick: (event, rowData) => { window.confirm(`Are you sure you want to change status to be { REVIEWED } ?`) && this.handleReviewStatus(rowData) },
						// 	position: 'row',
						// 	disabled: rowData.is_reviewed == true
						// }),
						// {
						// 	icon: () => <AssignmentReturnIcon />,
						// 	tooltip: 'Return to previous stage',
						// 	onClick: (event, rowData) => { window.confirm(`Are you sure you want to return ${rowData.invoice} to previous stage?`) && this.handleReturnToPreviousStage(rowData) },
						// 	position: 'row',
						// },
						rowData => ({
							icon: () => <ImInfo />,
							tooltip: 'Show order details',
							onClick: () => this._ShowOrderRef.current.handleOpen(rowData.id, rowData.order_number),
							position: 'row',
						}),
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
							}
							let url = '/order_item_stages/purchased.json?'
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.invoices,
										page: result.page - 1,
										totalCount: result.total,
									});
									window.breadcrumb.addBreadcrumb(result.breadcrumb);
								})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(Index)
