import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	CardContent,
	CardMedia, Link,
	Badge,
	Typography, FormControlLabel, Checkbox} from '@material-ui/core';
import NumericInput from 'react-numeric-input';
import $ from "jquery";
import {useSnackbar} from 'notistack';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SpeedDials from './_speed_dials'

export default function MediaControlCard({product, callBack}) {
	const {enqueueSnackbar} = useSnackbar();
	const classes = useStyles();
	const [maxSelect, setMaxSelect] = React.useState(product.qty);
	const [state, setState] = React.useState({
		qty: product.qty,
		original_qty: product.original_qty,
		remaining: product.qty,
		processed: product.processed,
		notes: product.notes,
		locked: product.locked,
		order_item_stage_id: product.order_item_stage_id,
		working: false
	});

	const handleQtyChange = (value) => {
		if (value > state.remaining || value <= 0 || value === null) return
		setState({...state, qty: value});
		callBack({
			selected: true,
			qty: value,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id
		})
	};

	const handleCheckBoxChange = (event) => {
		setState({...state, [event.target.name]: event.target.checked});
		callBack({
			selected: event.target.checked,
			qty: state.qty,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id
		})
	}

	const handleCallBackStageQty = (stageQty, updateQty=false) => {
		if (stageQty === 0) {
			setState({...state, disabled: true, qty: stageQty, processed: state.original_qty - stageQty, remaining: stageQty})
			callBack({selected: false, order_item_id: product.order_item_id, qty: stageQty, order_item_stage_id:product.order_item_stage_id })
		} else {
			setMaxSelect(stageQty)
			if (updateQty) {
				setState({...state, qty: stageQty, original_qty: state.processed + stageQty, remaining: stageQty})
			} else {
				setState({...state, qty: stageQty, processed: state.original_qty - stageQty, remaining: stageQty})
			}
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setState({...state, working: true});
		const data = {order_item_id: product.order_item_id, order_item_stage_id: product.order_item_stage_id, qty: state.qty}
		$.ajax({
			url: `/waybills/hub_processing`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function (response) {
				if (response.remaining_qty === 0) {
					reloadData(product.last_item ? product : null);
				} else {
					enqueueSnackbar(`successfully processed ${state.qty}`, {variant: 'success'})
					setMaxSelect(response.remaining_qty)
					setState({...state, working: false, qty: response.remaining_qty, remaining: response.remaining_qty, processed: response.processed});
				}
			},
			error: function (response) {
				setState({...state, working: false});
				enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	const customization = product.customizations.map(c =>
	{if (c.value !== null && c.value !== '')
		return (<Typography variant='subtitle1' color='textSecondary' key={c.name}>
			{c.name}: <b>{c.value}</b>
		</Typography>)
	})

	return (
		<div className='row' hidden={state.disabled}>
			<div className='col-md-12'>
				<Card className={classes.root}>
					<CardMedia className={classes.cover}>
						<Badge badgeContent='DG' invisible={!product.dg} overlap="circular" color="error">
							<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
						</Badge>
					</CardMedia>
					<div className={classes.details}>
						<CardContent className={classes.content}>
							<Typography component='h5' variant='h5'>
								<Link href={product.url} target="_blank">{product.name}</Link>
							</Typography>
							<div className='row'>
								<div className='col-md-7'>
									<div className='row'>
										<div className='col-md-5'>
											<Typography variant='subtitle1' color='textSecondary'>
												<b>{product.brand}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												Barcode: <b>{product.redeem_code}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												SKU: <b>{product.sku}</b>
											</Typography>
											<Typography variant='subtitle1' color='textSecondary'>
												Order# <b>{product.order_number}</b>
											</Typography>
										</div>
										<div className='col-md-7'>
											{customization}
										</div>
									</div>
									<div className='row'>
										<div className='col-md-1'>
											<FormControlLabel
												control={<Checkbox icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }}/>}
												                   checkedIcon={<CheckBoxIcon style={{fill: '#f6b67f', fontSize: 30}}/>}
												                   name='selected'
												                   defaultChecked={product.selected}
												                   onChange={handleCheckBoxChange}/>}
											/>
										</div>
										<div className='col-md-3 mt-3'>
											<NumericInput min={1} max={maxSelect} value={state.qty} mobile size={4} onChange={handleQtyChange} name='qty' disabled={!state.selected}/>
										</div>
									</div>
								</div>
								<div className='col-md-5'>
									<Typography variant='subtitle1' color='textSecondary'>
										Received by: <b>{product.created_by}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Received at: <b>{product.created_at}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Item value: <b>{product.price}</b>
									</Typography>
								</div>
							</div>
						</CardContent>
					</div>
					<div className='float-end'>
						<SpeedDials
							product={product}
							state={state}
							handleCallBackStageQty = {handleCallBackStageQty}
						/>
					</div>
				</Card>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 10,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
}));
