import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import queryString from 'query-string'
import Backdrop from '../shared/backdrop'
import ProductCard from "./_process_item_card";
import pureIcons from "../shared/pure_icons";
import ReactToPrint from "react-to-print";
import OrderStatusAlert from './_order_status_alert'
import ItemsList from "./items_list";

class InTransit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			customers_list: [],
			customers_list_address: [],
			customers_count: 0,
			products: [],
			global_control: {},
			product: null,
			title: '',
			waybill: '',
			box: ''
		};
		this._tableRef = React.createRef();
		this._orderStatusRef = React.createRef();
	}

	reloadData = (product = null, fullReload = false) => {
		if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 })
		if (product?.last_item) {
			this._orderStatusRef.current.handleOpen(product?.order_id, product?.basket_number, product?.qty);
		} else {
			this._tableRef.current.onQueryChange();
		}
	}

	async componentDidMount() {
		let params = queryString.parse(this.props.location.search)
		try {
			const response = await fetch(`/waybills/box_items.json?waybill=${params.waybill}&box=${params.box}&header_only=true`);
			const data = await response.json();
			this.setState({ customers_list: data.customers_list, customers_list_address: data.customers_list_address,
				customers_count: data.customers_count, products: data.products, global_control: data.global_control,
				title: data.title, waybill: params.waybill, box: params.box})
			window.breadcrumb.addBreadcrumb(data.breadcrumb);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	render() {
		let params = queryString.parse(this.props.location.search)
		const columns = [
			{
				title: '', field: 'name', render: rowData => {
					return <ProductCard product={rowData} key={rowData.order_item_stage_id} reloadData={this.reloadData} global_control={this.state.global_control} />
				}
			},
		]
		return (
			<div className='bg-white rounded'>
				<Backdrop open={this.state.working} />
				<OrderStatusAlert ref={this._orderStatusRef} reloadData={this.reloadData} waybill={this.state.waybill} box={this.state.box}/>
				<Table
					title={`${this.state.customers_count} Customers in this Shipment`}
					key={this.state.muiTableKey}
					options={{ debounceInterval: 500, filtering: false, sorting: false, focus: true }}
					columns={columns}
					tableRef={this._tableRef}
					actions={
						[
							{
								icon: () => <PrintCustomersList list={this.state.customers_list_address} />,
								isFreeAction: true,
								tooltip: 'Print Customers list'
							},
							{
								icon: () => <pureIcons.FiRefreshCw />,
								onClick: () => { this.reloadData(null, true) },
								isFreeAction: true,
								tooltip: 'Refresh'
							},
							{
								icon: () => <PrintItemsList products={this.state.products} title={this.state.title} customers_count={this.state.customers_count}/>,
								isFreeAction: true,
								tooltip: 'Print List',
							},
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
							}
							let url = `/waybills/box_items.json?waybill=${params.waybill}&box=${params.box}&`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.products,
										page: result.page - 1,
										totalCount: result.total,
									});
								}).catch(function (error) {
								})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(InTransit)

class CustomersList extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='d-none d-print-block'>
				<div className="row m-2">
					<table className="table table-borderless">
						<tbody>
							{this.props.list.map((l) =>
								<tr key={this.props.list.indexOf(l)}>
									{l.map((c) => <td className='fs-5'>
										<div className="pe-3 pb-3 ps-1 align-baseline border border-2 border-dark">{c}</div></td>)}
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
class PrintCustomersList extends React.Component {
	render() {
		document.title = 'boxes list'
		return (
			<div>
				<ReactToPrint
					trigger={() => <pureIcons.PrintIcon />}
					content={() => this.componentRef}
				/>
				<CustomersList ref={el => (this.componentRef = el)} list={this.props.list} />
			</div>
		);
	}
}


class PrintItemsList extends React.Component {
	render() {
		document.title = this.props.title
		return (
			<div>
				<ReactToPrint
					trigger={() => <pureIcons.FaFileInvoice />}
					content={() => this.componentRef}
				/>
				<ItemsList ref={el => (this.componentRef = el)} products={this.props.products} title={this.props.title} customers_count={this.props.customers_count}/>
			</div>
		);
	}
}
