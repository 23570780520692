// import React from 'react';
import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import pureIcons from "../pure_icons";

const defaultOptions = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  rowStyle: { whiteSpace: "normal", wordWrap: "break-word" },
  headerStyle: {
    whiteSpace: "nowrap",
    color: "#04423dd7",
    padding: "0.5em",
    backgroundColor: "#eff2f8",
  },
  cellStyle: { padding: "0.5em" },
  maxBodyWidth: "100px",
  sorting: true,
  search: true,
  paging: true,
  toolbar: true,
  filtering: true,
  exportButton: false,
  searchAutoFocus: true,
  paginationPosition: "top",
  maxBodyHeight: "85vh"
};

const Table = ({
  title = "",
  options,
  columns,
  data,
  actions,
  editable,
  localization,
  cellEditable,
  tableRef,
  detailPanel,
  onRowClick,
  onSelectionChange
}) => {
  const tableOptions = { ...defaultOptions, ...options };
  const [materialColumn] = useState(columns);

  return (
    <MaterialTable
      title={title}
      editable={editable}
      localization={localization}
      tableRef={tableRef}
      icons={pureIcons}
      options={{ ...tableOptions, emptyRowsWhenPaging: false }}
      columns={materialColumn}
      actions={actions}
      cellEditable={cellEditable}
      onSelectionChange={onSelectionChange}
      data={data}
      detailPanel={detailPanel}
      onRowClick={onRowClick}
    />
  );
};
export default Table;
