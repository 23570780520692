import React from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import $ from "jquery";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CsvBuilder } from "filefy";
import ReactToPrint from "react-to-print";
import Label from "./_order_label";
import Modal from "../../shared/modal/material_dialog";
import { Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ReadyForDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      working: false,
      muiTableKey: 0,
      provinces: [],
      cities: [],
      user_list: [],
    };
    this._tableRef = React.createRef();
  }

  reloadData() {
    this._tableRef.current.onQueryChange();
  }

  handleOutForDelivery = (rowData) => {
    let self = this;
    this.setState({ submitting: true });
    $.ajax({
      url: `/order_item_stages/process_to_out_for_delivery`,
      type: "POST",
      data: { group_ids: rowData.map((row) => row.group_id) },
      dataType: "json",
      success: function (response) {
        self.setState({ submitting: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ submitting: false });
      },
    });
  };

  render() {
    const columns = [
      { title: "Orders", field: "orders", sorting: false, filtering: false },
      {
        title: "Customer Phone",
        field: "phone",
        sorting: false,
        cellStyle: { width: "5%" },
        filtering: false,
      },
      {
        title: "Customer Name",
        field: "name",
        sorting: false,
        filtering: false,
      },
      {
        title: "Province",
        field: "province",
        sorting: false,
        editable: "never",
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={this.state.provinces}
            disableCloseOnSelect
            limitTags={1}
            onChange={(e, newValue) => {
              onFilterChanged(columnDef.tableData.id, newValue);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ),
      },
      {
        title: "City",
        field: "city",
        sorting: false,
        editable: "never",
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags-2"
            options={this.state.cities}
            disableCloseOnSelect
            limitTags={1}
            onChange={(e, newValue) => {
              onFilterChanged(columnDef.tableData.id, newValue);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ),
      },
      { title: "Current Address", field: "address", sorting: false, filtering: false },
      {
        title: "Items",
        field: "items_count",
        sorting: false,
        cellStyle: { width: "5%" },
        filtering: false,
      },
      { title: "Order Address", field: "order_delivery_info", sorting: false, filtering: false },
      { title: "Date", field: "create_at", filtering: false },
      {
        title: "User",
        field: "who",
        sorting: false,
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <Select
            name={columnDef.tableData.id}
            value={columnDef.tableData.who_id}
            onChange={(e) => {
              onFilterChanged(columnDef.tableData.id, e.target.value);
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {this.state.user_list.map((v) => (
              <MenuItem key={v[1]} value={v[1]}>
                {v[0]}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        title: "shopper_id",
        field: "shopper_id",
        filtering: false,
        hidden: true,
      },
      { title: "group_id", field: "group_id", filtering: false, hidden: true },
      {
        title: "Print",
        field: "print",
        sorting: false,
        filtering: false,
        cellStyle: { width: "2%" },
        render: (rowData) => (
          <PrintOrderLabel
            shopper_id={rowData.shopper_id}
            group_id={rowData.group_id}
          />
        ),
      },
    ];
    return (
      <div className="bg-white rounded">
        <Table
          title={"Ready For Delivery"}
          key={this.state.muiTableKey}
          options={{
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            selection: true,
          }}
          columns={columns}
          tableRef={this._tableRef}
          actions={[
            {
              icon: () => <pureIcons.FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              icon: () => <pureIcons.Export />,
              tooltip: <span>Export the selected rows!</span>,
              onClick: (e, rowData) => {
                const fileName = "Ready_For_Delivery";
                const builder = new CsvBuilder(fileName + ".csv");
                builder
                  .setColumns(columns.map((columnDef) => columnDef.title))
                  .addRows(
                    rowData.map((rowData) =>
                      columns.map((columnDef) => rowData[columnDef.field])
                    )
                  )
                  .exportFile();
              },
            },
            {
              icon: () => <pureIcons.FaShippingFast />,
              tooltip: <span>Out For Delivery</span>,
              onClick: (event, data) =>
                window.confirm(
                  `Are you sure you want to mark the selected orders as out for delivery?`
                ) && this.handleOutForDelivery(data),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              let url = "/order_item_stages/ready_for_delivery.json?";
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.records,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  if (this.state.provinces.length === 0) {
                    this.setState({
                      provinces: result.provinces,
                      cities: result.cities,
                      user_list: result.user_list,
                    });
                  }
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                });
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(ReadyForDelivery);

class PrintLabel extends React.Component {
  render() {
    return (
      <div>
        <style type="text/css" media="print">
          {"@page {size: landscape; }"}
        </style>
        <ReactToPrint
          trigger={() => (
            <Button type="submit" variant="outlined" color="primary">
              {" "}
              print
            </Button>
          )}
          content={() => this.componentRef}
        />
        <div className="d-none d-print-block">
          <Label
            ref={(el) => (this.componentRef = el)}
            data={this.props.shopper}
          />
        </div>
      </div>
    );
  }
}

function PrintOrderLabel(props) {
  const [open, setOpen] = React.useState(false);
  const [shopper, setShopper] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    fetchShopperData();
    setOpen(true);
  };

  const fetchShopperData = () => {
    const url = `/order_items/shopper_items_in_sorting_facility.json?shopper_id=${props.shopper_id}&stage=out_for_delivery&group_id=${props.group_id}&locale=ar`;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setShopper(result.shopper);
      });
  };

  return (
    <>
      <pureIcons.PrintIcon onClick={handleOpen} />
      <Modal
        isOpen={open}
        title=""
        handleClose={handleClose}
        maxHeight="60%"
        maxWidth="md"
        actions={<PrintLabel shopper={shopper} />}
      >
        {<Label data={shopper} />}
      </Modal>
    </>
  );
}
